
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ReservationTable from "@/components/reservations/List2.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
    name: "reservation-list",
    components: {
        ReservationTable,
    },
    props:{
        setValue: String,
        setCountry: String,
    },
    data: function () {
        return {
            myLoading: false,
        }
    },
    methods: {},

    setup() {
        return {
           // myList,
        };
    },
});
